import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Injector, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { ItemsType, ScreenBreakpointsService } from '@storefront/ng.core';
import { DeliveryType } from '@tajer/api';
import { Observable, Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { IonicHeaderConfiguration, IonicHeaderDesignOptions } from '@storefront/ng.themes';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { CustomStorePageStoreDto } from '@storefront/ng.themes/lib/models';
import { DeliveryTypeAndTimeComponent, DeliveryTypeAndTimeComponentContext } from '../delivery-type-and-time/delivery-type-and-time.component';
import { DialogRef, overlayConfigFactory } from 'ngx-modialog-7';
import { BSModalContext } from 'ngx-modialog-7/plugins/bootstrap';
import { PickupDatePickerComponent, PickupDatePickerComponentContext } from '../pickup-date-picker/pickup-date-picker.component';
import { PluginRegistryService } from 'src/app/plugins/core';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [DatePipe]
})
export class HeaderComponent extends BaseComponent {

  //#region variables
  @Input() enableBack: boolean = false;
  @Input() showMenu: boolean = false;
  @Input() openSearch = false;
  public get color(): string {

    return this.storeDesignOptions?.headerColor ?? this.storeDesignOptions?.color;
  }
  public get frontColor(): string {
    return this.storeDesignOptions?.headerFrontColor ?? this.storeDesignOptions?.frontColor;
  }
  searchIconColor: string = this.frontColor;
  displaySearchboxOnHome: boolean;
  searchTerm = '';
  searchTerm$ = new Subject<string>();
  @Output() search = new EventEmitter<string>();
  deliveryType = DeliveryType;

  desginOptions: IonicHeaderDesignOptions;
  configuration: IonicHeaderConfiguration;

  //merch theme
  merchDesginOptions: IonicHeaderDesignOptions;
  merchConfiguration: IonicHeaderConfiguration;

  //shopper theme
  shopperDesginOptions: IonicHeaderDesignOptions;
  shopperConfiguration: IonicHeaderConfiguration;

  //restaurants theme
  restaurantsDesginOptions: IonicHeaderDesignOptions;
  restaurantsConfiguration: IonicHeaderConfiguration;

  //restaurants theme
  anvogueDesginOptions: AnvogueHeaderDesignOptions;
  anvogueConfiguration: AnvogueHeaderConfiguration;

  @ViewChild("chooseLanguageRef", { static: false }) tempRef: TemplateRef<any>;
  dialogRef: DialogRef<any>;

  //merch theme
  searchFilterType:number=null;

  public get mainBtnBackgroundColor(): string {
      return (
        this.storeDesignOptions?.mainBtnBackgroundColor ?? this.frontColor
      );
  }

  public get mainBtnFontColor(): string {
      return this.storeDesignOptions?.mainBtnFontColor ?? this.color;
  }

  public get mainBtnTitle(): string {
    return this.storeDesignOptions['mainBtnTitle'] ? this.storeDesignOptions['mainBtnTitle'][this.currentLang] : this.trsnalteApp.translate('AllCategories');
  }

  public get topHeaderSectionBackgroundColor(): string {
    return this.storeDesignOptions?.topHeaderSectionBackgroundColor ?? this.color;
  }

  public get topNaveSectionBackgroundColor(): string {
    return this.storeDesignOptions?.topNavBackgroundColor ?? this.color;
  }

  public get topNaveSectionFontColor(): string {
    return this.storeDesignOptions?.topNavFontColor ?? this.frontColor;
  }

  public get headerFontColor(): string {
    return this.storeDesignOptions?.headerFrontColor ?? this.frontColor;
  }

  public get headerBackgroundColor(): string {
    return this.storeDesignOptions?.headerColor ?? this.color;
  }

  //shopper
  searchForm:boolean
  pagesMenuOpen:boolean

  //retsurants
  isOpen= false;

  public get menuBackgroundColor(): string {
    return (
      this.storeDesignOptions?.menuBackgroundColorOnHeader ?? this.color
    );
}

  public get menuFontColor(): string {
    return (
      this.storeDesignOptions?.menuFontColorOnHeader ?? this.frontColor
    );
  }

  public get subMenuBackgroundColor(): string {
    return (
      this.storeDesignOptions?.subMenuBackgroundColorOnHeader ?? this.color
    );
}

  public get subMenuFontColor(): string {
    return (
      this.storeDesignOptions?.subMenuFontColorOnHeader ?? this.frontColor
    );
  }

  @ViewChild('headerLoadingModalRef', { static: false })
  headerLoadingModalRef: TemplateRef<any>;
  //#endregion

  public platform: any
  constructor(
    injector: Injector,
    // private menu: MenuController,
    // private modalController: ModalController,
    public datePipe: DatePipe,
    public localizeService: LocalizeRouterService,
    public screenBreakpointsService: ScreenBreakpointsService,
    private eRef: ElementRef,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.subscribeSearch(this.searchTerm$);
    if (this.currentTheme == 'ionic' || this.currentTheme == 'customizedIonic') {
      this.setIonicThemeOptions();
    } else if (this.currentTheme == 'merch') {
      this.setMerchThemeOptions();
    } else if (this.currentTheme == 'shopper') {
      this.setShopperThemeOptions();
    } else if (this.currentTheme == 'restaurants') {
      this.setRestaurantsThemeOptions();
    } else if (this.currentTheme == 'anvogue') {
      this.setAnvogueThemeOptions();
    }
  }

  setIonicThemeOptions() {
    this.desginOptions = {
      openSearch: this.openSearch,
      showBacKButton: this.enableBack,
      showSideMenuButton: this.showMenu && (!this.enableBack),
      showSearchOnHeader: this.storeDesignOptions?.showSearchOnHeader,
      hideSearchButtonOnHeader: this.storeDesignOptions?.hideSearchButtonOnHeader,
      showButtonsWithNamesOnHeader: this.storeDesignOptions?.showButtonsWithNamesOnHeader,
      searchIconColor: this.searchIconColor,
      showChangeLanguagePopupButtonOnMainHeader: (this.storeDesignOptions?.showChangeLanguagePopupButtonOnMainHeader && this.storeAppService.isMultilingualEnabled),
      showLanguageButtonsOnMainHeader: (this.storeDesignOptions?.showLanguageButtonsOnMainHeader && this.storeAppService.generateLanguagesArray?.length > 0),
      showLineVerticalAfterLangIcons: this.showLineVerticalAfterLangIcons(),
      showAccountOnHeader: this.storeDesignOptions?.showAccountOnHeader,
      showNotificationsButton: this.configFeatures?.notifications,
      showFavoriteOnHeader: this.storeDesignOptions?.showFavoriteOnHeader && this.storeOptions.isFavoriteItemsEnabled,
      showCartOnHeader: this.storeDesignOptions?.showCartOnHeader,
      showSideMenuCart: this.storeDesignOptions?.showSideMenuCart,
      showMenuHeader: this.storeDesignOptions?.showMenuHeader,
      showLargeMenuHeader: this.storeAppService.menuCategories.length < 5,
      thinScrollMenuHeader: true,
      showStickyButtonOnMenuHeader: this.storeDesignOptions?.showStickyButtonOnMenuHeader,
      showChangeLanguagePopupButtonOnMenuHeader: this.storeDesignOptions?.showChangeLanguagePopupButtonOnMenuHeader && this.storeAppService.isMultilingualEnabled,
      showLanguageButtonsOnMenuHeader: this.storeDesignOptions?.showLanguageButtonsOnMenuHeader && this.storeAppService.generateLanguagesArray?.length > 0,
      isMultiOutletEnabled: this.storeAppService.isMultiOutletEnabled,
      color: this.color,
      frontColor: this.frontColor,
    }

    this.configuration = {
      accountUrl: [this.getUrl('/profile')],
      notificationsUrl: [this.getUrl('/notifications')],
      favoriteUrl: this.getUrl('/my-favorite'),
      cartUrl: this.getUrl('/cart'),
      searchOrderUrl: this.getUrl('/search-order'),
      clientLogoImage: this.storeAppService?.storeConfig?.logo,
      defaultClientLogoImage: this.storeAppService.defaultClientLogoUrl,
      logoUrl: this.getUrl('/'),
    }
  }

  setMerchThemeOptions() {
    this.merchDesginOptions = {
      openSearch: this.openSearch,
      showBacKButton: this.enableBack,
      showSideMenuButton: this.showMenu && (!this.enableBack),
      showSearchOnHeader: this.storeDesignOptions?.showSearchOnHeader,
      hideSearchButtonOnHeader: this.storeDesignOptions?.hideSearchButtonOnHeader,
      showButtonsWithNamesOnHeader: this.storeDesignOptions?.showButtonsWithNamesOnHeader,
      searchIconColor: this.searchIconColor,
      showChangeLanguagePopupButtonOnMainHeader: (this.storeDesignOptions?.showChangeLanguagePopupButtonOnMainHeader && this.storeAppService.isMultilingualEnabled),
      showLanguageButtonsOnMainHeader: (this.storeDesignOptions?.showLanguageButtonsOnMainHeader && this.storeAppService.generateLanguagesArray?.length > 0),
      showLineVerticalAfterLangIcons: this.showLineVerticalAfterLangIcons(),
      showAccountOnHeader: this.storeDesignOptions?.showAccountOnHeader,
      showNotificationsButton: this.configFeatures?.notifications,
      showFavoriteOnHeader: this.storeDesignOptions?.showFavoriteOnHeader && this.storeOptions.isFavoriteItemsEnabled,
      showCartOnHeader: this.storeDesignOptions?.showCartOnHeader,
      showSideMenuCart: this.storeDesignOptions?.showSideMenuCart,
      showMenuHeader: this.storeDesignOptions?.showMenuHeader,
      showLargeMenuHeader: this.storeAppService.menuCategories.length < 5,
      thinScrollMenuHeader: true,
      showStickyButtonOnMenuHeader: this.storeDesignOptions?.showStickyButtonOnMenuHeader,
      showChangeLanguagePopupButtonOnMenuHeader: this.storeDesignOptions?.showChangeLanguagePopupButtonOnMenuHeader && this.storeAppService.isMultilingualEnabled,
      showLanguageButtonsOnMenuHeader: this.storeDesignOptions?.showLanguageButtonsOnMenuHeader && this.storeAppService.generateLanguagesArray?.length > 0,
      isMultiOutletEnabled: this.storeAppService.isMultiOutletEnabled,
      color: this.color,
      frontColor: this.frontColor,
    }

    this.merchConfiguration = {
      accountUrl: [this.getUrl('/profile')],
      notificationsUrl: [this.getUrl('/notifications')],
      favoriteUrl: this.getUrl('/my-favorite'),
      cartUrl: this.getUrl('/cart'),
      searchOrderUrl: this.getUrl('/search-order'),
      clientLogoImage: this.storeAppService?.storeConfig?.logo,
      defaultClientLogoImage: this.storeAppService.defaultClientLogoUrl,
      logoUrl: this.getUrl('/'),
    }
  }

  setShopperThemeOptions() {
    this.shopperDesginOptions = {
      openSearch: this.openSearch,
      showBacKButton: this.enableBack,
      showSideMenuButton: this.showMenu && (!this.enableBack),
      showSearchOnHeader: this.storeDesignOptions?.showSearchOnHeader,
      hideSearchButtonOnHeader: this.storeDesignOptions?.hideSearchButtonOnHeader,
      showButtonsWithNamesOnHeader: this.storeDesignOptions?.showButtonsWithNamesOnHeader,
      searchIconColor: this.searchIconColor,
      showChangeLanguagePopupButtonOnMainHeader: (this.storeDesignOptions?.showChangeLanguagePopupButtonOnMainHeader && this.storeAppService.isMultilingualEnabled),
      showLanguageButtonsOnMainHeader: (this.storeDesignOptions?.showLanguageButtonsOnMainHeader && this.storeAppService.generateLanguagesArray?.length > 0),
      showLineVerticalAfterLangIcons: this.showLineVerticalAfterLangIcons(),
      showAccountOnHeader: this.storeDesignOptions?.showAccountOnHeader,
      showNotificationsButton: this.configFeatures?.notifications,
      showFavoriteOnHeader: this.storeDesignOptions?.showFavoriteOnHeader && this.storeOptions.isFavoriteItemsEnabled,
      showCartOnHeader: this.storeDesignOptions?.showCartOnHeader,
      showSideMenuCart: this.storeDesignOptions?.showSideMenuCart,
      showMenuHeader: this.storeDesignOptions?.showMenuHeader,
      showLargeMenuHeader: this.storeAppService.menuCategories.length < 5,
      thinScrollMenuHeader: true,
      showStickyButtonOnMenuHeader: this.storeDesignOptions?.showStickyButtonOnMenuHeader,
      showChangeLanguagePopupButtonOnMenuHeader: this.storeDesignOptions?.showChangeLanguagePopupButtonOnMenuHeader && this.storeAppService.isMultilingualEnabled,
      showLanguageButtonsOnMenuHeader: this.storeDesignOptions?.showLanguageButtonsOnMenuHeader && this.storeAppService.generateLanguagesArray?.length > 0,
      isMultiOutletEnabled: this.storeAppService.isMultiOutletEnabled,
      color: this.color,
      frontColor: this.frontColor,
    }

    this.shopperConfiguration = {
      accountUrl: [this.getUrl('/profile')],
      notificationsUrl: [this.getUrl('/notifications')],
      favoriteUrl: this.getUrl('/my-favorite'),
      cartUrl: this.getUrl('/cart'),
      searchOrderUrl: this.getUrl('/search-order'),
      clientLogoImage: this.storeAppService?.storeConfig?.logo,
      defaultClientLogoImage: this.storeAppService.defaultClientLogoUrl,
      logoUrl: this.getUrl('/'),
    }
  }

  setRestaurantsThemeOptions() {
    this.restaurantsDesginOptions = {
      openSearch: this.openSearch,
      showBacKButton: this.enableBack,
      showSideMenuButton: this.showMenu && (!this.enableBack),
      showSearchOnHeader: this.storeDesignOptions?.showSearchOnHeader,
      hideSearchButtonOnHeader: this.storeDesignOptions?.hideSearchButtonOnHeader,
      showButtonsWithNamesOnHeader: this.storeDesignOptions?.showButtonsWithNamesOnHeader,
      searchIconColor: this.searchIconColor,
      showChangeLanguagePopupButtonOnMainHeader: (this.storeDesignOptions?.showChangeLanguagePopupButtonOnMainHeader && this.storeAppService.isMultilingualEnabled),
      showLanguageButtonsOnMainHeader: (this.storeDesignOptions?.showLanguageButtonsOnMainHeader && this.storeAppService.generateLanguagesArray?.length > 0),
      showLineVerticalAfterLangIcons: this.showLineVerticalAfterLangIcons(),
      showAccountOnHeader: this.storeDesignOptions?.showAccountOnHeader,
      showNotificationsButton: this.configFeatures?.notifications,
      showFavoriteOnHeader: this.storeDesignOptions?.showFavoriteOnHeader && this.storeOptions.isFavoriteItemsEnabled,
      showCartOnHeader: this.storeDesignOptions?.showCartOnHeader,
      showSideMenuCart: this.storeDesignOptions?.showSideMenuCart,
      showMenuHeader: this.storeDesignOptions?.showMenuHeader,
      showLargeMenuHeader: this.storeAppService.menuCategories.length < 5,
      thinScrollMenuHeader: true,
      showStickyButtonOnMenuHeader: this.storeDesignOptions?.showStickyButtonOnMenuHeader,
      showChangeLanguagePopupButtonOnMenuHeader: this.storeDesignOptions?.showChangeLanguagePopupButtonOnMenuHeader && this.storeAppService.isMultilingualEnabled,
      showLanguageButtonsOnMenuHeader: this.storeDesignOptions?.showLanguageButtonsOnMenuHeader && this.storeAppService.generateLanguagesArray?.length > 0,
      isMultiOutletEnabled: this.storeAppService.isMultiOutletEnabled,
      color: this.color,
      frontColor: this.frontColor,
    }

    this.restaurantsConfiguration = {
      accountUrl: [this.getUrl('/profile')],
      notificationsUrl: [this.getUrl('/notifications')],
      favoriteUrl: this.getUrl('/my-favorite'),
      cartUrl: this.getUrl('/cart'),
      searchOrderUrl: this.getUrl('/search-order'),
      clientLogoImage: this.storeAppService?.storeConfig?.logo,
      defaultClientLogoImage: this.storeAppService.defaultClientLogoUrl,
      logoUrl: this.getUrl('/'),
    }
  }


  setAnvogueThemeOptions() {
    this.anvogueDesginOptions = {
      topNavBackgroundColor: this.topNaveSectionBackgroundColor,
      topNavFontColor: this.topNaveSectionFontColor,
      headerColor: this.headerBackgroundColor,
      headerFrontColor: this.headerFontColor,
      showSearchOnHeader: this.storeDesignOptions?.showSearchOnHeader,
      showAccountOnHeader: this.storeDesignOptions?.showAccountOnHeader,
      showFavoriteOnHeader: this.storeDesignOptions?.showFavoriteOnHeader && this.storeOptions.isFavoriteItemsEnabled,
      showCartOnHeader: this.storeDesignOptions?.showCartOnHeader,
      searchOrderUrl: '',
      showSocialMediaLinksOnTopHeader: this.storeDesignOptions?.showSocialMediaLinksOnTopHeader,
      showLanguageSelectOnHeader: this.storeDesignOptions?.showLanguageSelectOnHeader,

      openSearch: this.openSearch,
      showBacKButton: this.enableBack,
      showSideMenuButton: this.showMenu && (!this.enableBack),
      hideSearchButtonOnHeader: this.storeDesignOptions?.hideSearchButtonOnHeader,
      showButtonsWithNamesOnHeader: this.storeDesignOptions?.showButtonsWithNamesOnHeader,
      searchIconColor: this.searchIconColor,
      showChangeLanguagePopupButtonOnMainHeader: (this.storeDesignOptions?.showChangeLanguagePopupButtonOnMainHeader && this.storeAppService.isMultilingualEnabled),
      showLanguageButtonsOnMainHeader: (this.storeDesignOptions?.showLanguageButtonsOnMainHeader && this.storeAppService.generateLanguagesArray?.length > 0),
      showLineVerticalAfterLangIcons: this.showLineVerticalAfterLangIcons(),
      showNotificationsButton: this.configFeatures?.notifications,
      showSideMenuCart: this.storeDesignOptions?.showSideMenuCart,
      showMenuHeader: this.storeDesignOptions?.showMenuHeader,
      showLargeMenuHeader: this.storeAppService.menuCategories.length < 5,
      thinScrollMenuHeader: true,
      showStickyButtonOnMenuHeader: this.storeDesignOptions?.showStickyButtonOnMenuHeader,
      showChangeLanguagePopupButtonOnMenuHeader: this.storeDesignOptions?.showChangeLanguagePopupButtonOnMenuHeader && this.storeAppService.isMultilingualEnabled,
      showLanguageButtonsOnMenuHeader: this.storeDesignOptions?.showLanguageButtonsOnMenuHeader && this.storeAppService.generateLanguagesArray?.length > 0,
      isMultiOutletEnabled: this.storeAppService.isMultiOutletEnabled,
      color: this.color,
      frontColor: this.frontColor,
    }

    this.anvogueConfiguration = {
      accountUrl: [this.getUrl('/profile')],
      registerUrl: [this.getUrl('/register')],
      notificationsUrl: [this.getUrl('/notifications')],
      favoriteUrl: this.getUrl('/my-favorite'),
      cartUrl: this.getUrl('/cart'),
      searchOrderUrl: this.getUrl('/search-order'),
      clientLogoImage: this.storeAppService?.storeConfig?.logo,
      defaultClientLogoImage: this.storeAppService.defaultClientLogoUrl,
      logoUrl: this.getUrl('/'),
      whatsApp: this.customerServiceInfo?.whatsApp,
      telegram: this.customerServiceInfo?.telegram,
      instagram: this.socialMediaInfo?.instagram, //this.storeConfig.instagram,
      twitter: this.socialMediaInfo?.twitter, //this.storeConfig.twitter,
      facebook: this.socialMediaInfo?.facebook, //this.storeConfig.facebook,
      youtube: this.socialMediaInfo?.youtube, //this.storeConfig.youtube,
      snapchat: this.socialMediaInfo?.snapchat, //this.storeConfig.snapchat,
      ticktok: this.socialMediaInfo?.ticktok, //this.storeConfig.ticktok,
      linkedin: null, //this.storeConfig.linkedin,
    }
  }

  subscribeSearch(searchTerm: Observable<string>) {
    searchTerm
      .pipe(
        debounceTime(100),
        distinctUntilChanged(),
      )
      .subscribe(
        searchTerm => {
          this.searchTerm = searchTerm;
          this.search.emit(searchTerm);
        }
      );
  }

  onSearch() {
    const url = this.getUrl('/search/' + this.searchTerm);
    this.pluginRegistry.trigger('implementSearchEvent', this.searchTerm);
    if (this.isSegmentEnabled) { this.segmentService.implementSearchEvent(this.searchTerm) }

    if (this.searchFilterType == ItemsType.FeaturedProducts) {
      if(this.searchTerm?.trim().length==0){
        this.router.navigate(['/catalog/filters/featured'])
      }else{
        this.router.navigate([url], {
          queryParams: {
            searchFilter : ItemsType.FeaturedProducts
          },
        });
      }
    } else
      if (this.searchFilterType == ItemsType.LatestProducts) {
        if(this.searchTerm?.trim().length==0){
          this.router.navigate(['/catalog/filters/Latest'])
        }else{
          this.router.navigate([url], {
            queryParams: {
              searchFilter : ItemsType.LatestProducts
            },
          });
        }
      }else
      if (this.searchFilterType == ItemsType.BestsellerProducts) {
        this.router.navigate([url], {
          queryParams: {
            searchFilter : ItemsType.BestsellerProducts
          },
        });
      }else
      if (this.searchFilterType == ItemsType.OffersProducts) {
        this.router.navigate([url], {
          queryParams: {
            searchFilter : ItemsType.OffersProducts
          },
        });
      } else {
        this.router.navigate([url]);
      }
  }

  onClear() {
    this.searchTerm = '';
    this.search.emit(this.searchTerm);
    const url = this.getUrl('/catalog');
    this.router.navigate([url]);
  }

  ngOnDestroy(): void {
    this.searchTerm$.complete();
    this.searchTerm$ = null;
  }

  openCartMenu() {
    this.orderAppService.openCart();
    setTimeout(() => {
      this.cartAppService.sideMenuCartOpened=true;
    }, 100);
  }

  showLineVerticalAfterLangIcons(): boolean {
    return (this.storeDesignOptions?.showLanguageButtonsOnMainHeader && this.storeAppService.isMultilingualEnabled &&
      (this.storeDesignOptions?.showAccountOnHeader || this.configFeatures?.notifications || (this.storeDesignOptions?.showSearchOnHeader && !this.screenBreakpointsService.isSizeLgOrUp) ||
        (this.storeDesignOptions?.showFavoriteOnHeader && this.storeOptions.isFavoriteItemsEnabled) ||
        this.storeDesignOptions?.showCartOnHeader))
  }

  async showDeliveryTypeAndTimeModal() {
    const componentContext = <DeliveryTypeAndTimeComponentContext>{
      homePage: true,
      isDeliveryTypeAndTimeModal: true,
      size: 'sm',
    };

    const dialogRef = this.modal.open(
      DeliveryTypeAndTimeComponent,
      overlayConfigFactory(componentContext)
    );

    dialogRef.result.then(
      (result) => {
        if (result) {
        }
      },
      () => {
        return;
      }
    );
  }

  async showDatePickerModal() {
    const componentContext = <PickupDatePickerComponentContext>{
      header: true,
      isOpenCalendar: this.orderAppService.order?.date || !this.currentOutlet.availableForLiveOrders ? true : false,
      dialogClass: (this.currentTheme != 'ionic' && this.currentTheme != 'customizedIonic') ? 'modal-x-sm' : '',
      size: (this.currentTheme == 'ionic' || this.currentTheme == 'customizedIonic') ? 'sm' : ''
    };

    const dialogRef = this.modal.open(
      PickupDatePickerComponent,
      overlayConfigFactory(componentContext)
    );

    dialogRef.result.then(
      (result) => {
        if (result) {
        }
      },
      () => {
        return;
      }
    );
  }

  openChooseLanguageAlert() {
    const componentContext= <BSModalContext> {
      inElement: true,
      dialogClass:'modal-x-sm'
    };

    this.dialogRef = this.modal.open(this.tempRef,overlayConfigFactory(componentContext))

    this.dialogRef.result.then(
      (result) => {
        if (result) {
        }
      },
      () => {
        return;
      }
    );
  }

  closeChooseLanguageAlert(){
    this.dialogRef?.close();
  }

  onChangeLang(lang: string) {
    this.customerAppService.onChangeLang(lang);
    this.dialogRef?.close();
  }

  onChangeCurrency(currencyId: string) {
    this.currnetCurrnecyId = currencyId;
    window.location.reload();
    this.openHeaderLoadingModal();
    //this.onRefresh();
  }

  openHeaderLoadingModal() {
    const componentContext = <BSModalContext>{
      inElement: true,
      dialogClass: 'modal-x-sm',
      isBlocking: true,
    };

    this.dialogRef = this.modal.open(
      this.headerLoadingModalRef,
      overlayConfigFactory(componentContext)
    );

    this.dialogRef.onDestroy.subscribe(() => {
      if (this.isBrowser) {
        document.body.classList.remove('modal-open');
      }
    });

    this.dialogRef.result.then(
      (result) => {
        if (result) {
        }
      },
      () => {
        return;
      }
    );
  }

  // @HostListener('window:resize', ['$event'])
  // onWindowResize() {
  //   this.setIonicThemeOptions();
  // }

  //new theme
  showCategories:boolean;
  navbarOpen:boolean;
  categoryOne:boolean=true;

  get mainCategories(){
    return   this.storeAppService.mainCategories.map(c => {
      return {
        id: c.id,
        name: this.getValue(c),
        url: this.entityUtilityService.getCategoryUrl(c),
        image: c.image
      } as CustomStorePageStoreDto | any;
    });
  }

  isParentCategory(categoryId: string){
    let category=this.storeAppService.allCategories.find(c=>c.parentId==categoryId)
    return category?true: false;
  }

  getCategoryChildren(categoryId: string){
    return this.storeAppService.allCategories.filter(c=>c.parentId==categoryId).map(c => {
      return {
        id: c.id,
        name: this.getValue(c),
        url: this.entityUtilityService.getCategoryUrl(c),
        image: c.image
      } as CustomStorePageStoreDto;
    });
  }

  openSideMenu(){
    setTimeout(() => {
      this.htmlDocApp.sideMenuOpened=true;
    }, 100);
  }

  public get iconCart(){
    let iconCartName:string;
    if (
      this.storeDesignOptions?.cartIcon == 1
    ) {
      iconCartName = "bag-add-outline"
    } else {
      iconCartName = "cart-outline"
    }
    return iconCartName;
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(this.navbarOpen && !this.eRef.nativeElement.contains(event.target)) {
      this.navbarOpen = false;
    }
  }

  //for merch only
  get headerLogoCenteredOnMobile(){
    return this.screenBreakpointsService.isSizeSmOrDown && this.storeDesignOptions?.centeredlogoOnMobile;
  }


  //#region anvogue-theme
showSearchModal: boolean = false;
showLoginModal: boolean = false;
showWishlistModal: boolean = false;
showCartsModal: boolean = false;
showMenuMobile: boolean = false;
  //#endregion
}

interface AnvogueHeaderDesignOptions extends AnvogueDesignOptions {
  headerFrontColor: string;
  topNavBackgroundColor: string;
  headerColor: string;
  topNavFontColor: string;
  showAccountOnHeader: boolean;
  showFavoriteOnHeader: boolean;
  showCartOnHeader: boolean;
  showSearchOnHeader: boolean;
  showLanguageSelectOnHeader: boolean;
  searchOrderUrl: string;

  showSocialMediaLinksOnTopHeader: boolean;


  openSearch: boolean;
  showBacKButton: boolean;
  showSideMenuButton: boolean;
  hideSearchButtonOnHeader: boolean;
  showButtonsWithNamesOnHeader: boolean;
  searchIconColor: string;
  showChangeLanguagePopupButtonOnMainHeader: boolean;
  showLanguageButtonsOnMainHeader: boolean;
  showLineVerticalAfterLangIcons: boolean;
  showNotificationsButton: boolean;
  showSideMenuCart: boolean;
  showMenuHeader: boolean;
  showLargeMenuHeader: boolean;
  thinScrollMenuHeader: boolean;
  showStickyButtonOnMenuHeader: boolean;
  showChangeLanguagePopupButtonOnMenuHeader: boolean;
  showLanguageButtonsOnMenuHeader: boolean;
  isMultiOutletEnabled: boolean;
}
interface AnvogueHeaderConfiguration extends AnvogueConfigurations {
  /** Router Link Property */
  accountUrl: any[] | string;
  /** Router Link Property */
  registerUrl: any[] | string;
  /** Router Link Property */
  notificationsUrl: any[] | string;
  /** Router Link Property */
  favoriteUrl: any[] | string;
  /** Router Link Property */
  cartUrl: any[] | string;
  /** Router Link Property */
  searchOrderUrl: any[] | string;
  /** Router Link Property */
  instagram: string;
  facebook: string;
  twitter: string;
  youtube: string;
  snapchat: string;
  ticktok: string;
  linkedin: string;
  whatsApp: string;
  telegram: string;
}
interface AnvogueDesignOptions {
  color?: string;
  frontColor?: string;
  hideFooter?: boolean;
}
export interface AnvogueConfigurations {
  defaultClientLogoImage?: string;
  clientLogoImage?: string;
  /** Router Link Property */
  logoUrl?: any[] | string;
  storeName?: string;
  storeAbout?: string;
}
